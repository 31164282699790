@use "./vars" as *;

$neutral-black: #212121;
$color-underline: #9a3d3d;
$custom-ease-out: cubic-bezier(0.165, 0.84, 0.44, 1);

.navigation-container {
    align-items: center;
}

.header-container {
    text-align: center;
    margin-top: 15px;
}

.header {
    position: fixed;
    width: 100%;
    z-index: 100;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    padding: 0;
    border-bottom: 1px solid rgba($dark-bg, 0.1);

    &.is-scrolling {
        backdrop-filter: blur(10px);
        background-color: rgba(darken($dark-bg, 2), 0.85);
        border-bottom: 1px solid rgba($kelly-green, 0.05);

        .header-container>a>img {
            width: 120px;
        }

        .header-container>nav {
            transform: scale(0.95);
            height: 50px;
        }
    }
}

.logo {
    justify-content: center;
    margin: auto;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-1px);
    }

    >img {
        transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
        width: 200px;
    }
}

nav {
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    height: 70px;
    position: relative;
    align-items: center;
    justify-content: center;

    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        height: 1px;
        background: linear-gradient(
            to right,
            transparent,
            rgba($kelly-green, 0.1),
            rgba($kelly-green, 0.15),
            rgba($kelly-green, 0.1),
            transparent
        );
    }

    .main-nav {
        li {
            padding-top: 2px;
            padding-bottom: 2px;
            margin-right: 15px;
            position: relative;

            a {
                font-variant-caps: all-small-caps;
                font-weight: 300;
                font-size: 0.95rem;
                font-family: "Inter", sans-serif;
                text-decoration: none;
                padding: 6px 12px;
                transition: all 200ms ease;
                background-color: transparent;
                color: rgba($kelly-green, 0.9);

                &:hover {
                    color: rgba($kelly-green, 1);
                    background-color: rgba($kelly-green, 0.03);
                    border-color: rgba($kelly-green, 0.15);
                }

                &:active {
                    transform: translateY(1px);
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {}