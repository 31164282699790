@import url('../../../node_modules/@picocss/pico/css/pico.min.css');
@import "./partials/nav-centered";
@import "./partials/vars";

$bg-gradient: #090909;

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: "Inter", sans-serif;
    // background-color: ;
}

a {
    font-family: "Recursive", monospace;
    color: darken($color: $nav-bg-light, $amount: 20);

    text-decoration-color: rgba($color: $kelly-green, $alpha: 0.5);
    text-decoration-thickness: 1px;

    &:hover {
        // color: $kelly-green;
        text-decoration-color: $kelly-green;
    }
}

html {
    background-color: $dark-bg;
    opacity: 1;
    background-image: radial-gradient($bg-gradient 0.7000000000000001px, transparent 0.7000000000000001px), radial-gradient($bg-gradient 0.7000000000000001px, $dark-bg 0.7000000000000001px);
    background-size: 28px 28px;
    background-repeat: repeat;
    background-position: 0 0, 14px 14px;
}

.container {
    max-width: 767px;

    @media (width >= 576px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

p {
    font-size: 0.9rem;
    line-height: 1.6rem;
}

.main {
    padding-top: 175px;
}

.link {
    color: #90EE90;
}

.socials {
    display: flex;
    gap: 1rem;
    padding: 0;
    margin: 0;
    li {
        list-style: none;
    }
    
    a {
        color: inherit;
        font-size: 1.5rem;
        transition: 0.2s ease;
        
        &:hover {
            color: rgba($kelly-green, 0.9);
            opacity: 0.8;
            transform: translateY(-2px);
        }
    }
}

footer {

    p,
    small {
        text-align: center;

        a {
            display: block;
            padding-bottom: 5px;
            color: $kelly-green;
            text-decoration: none;
            opacity: .8;
            font-weight: 100;
            font-variant: small-caps;
        }

    }
}

// Target links specifically in the services/work page content paragraphs
.main p > a {
  font-family: "Recursive", monospace;
  color: rgba($kelly-green, 0.75);
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;
  
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background: linear-gradient(
      to right,
      transparent,
      rgba($kelly-green, 0.3),
      rgba($kelly-green, 0.5),
      rgba($kelly-green, 0.3),
      transparent
    );
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  }
  
  &:hover {
    color: $kelly-green;
    
    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}

.articles {
  margin: 2rem 0;
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin: 0.75rem 0;
  }
  
  a {
    font-family: "Inter", sans-serif;
    color: rgba($kelly-green, 0.8);
    text-decoration: underline;
    text-decoration-color: rgba($kelly-green, 0.3);
    font-size: 0.95rem;
    padding: 0.5rem 0;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
    

    
    &:hover {
      color: $kelly-green;
      text-decoration-color: $kelly-green;
      
      &::before {
        opacity: 0.8;
        transform: translateX(0);
      }
    }
  }
}